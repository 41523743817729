import React, { useState, useEffect } from 'react';
import axios from 'axios';
import Snackbar from '@mui/material/Snackbar';

const App = () => {
  const [inputText, setInputText] = useState('');
  const [questions, setQuestions] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [editingQuestion, setEditingQuestion] = useState(null);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [isSubmitModalOpen, setIsSubmitModalOpen] = useState(false);
  const [categories, setCategories] = useState({});
  const [selectedCategory, setSelectedCategory] = useState('');
  const [selectedSubcategory, setSelectedSubcategory] = useState('');
  const [subcategories, setSubcategories] = useState([]);

  const [isAddSubcategoryModalOpen, setIsAddSubcategoryModalOpen] = useState(false);
  const [newSubcategoryName, setNewSubcategoryName] = useState('');
  const [newSubcategoryCategory, setNewSubcategoryCategory] = useState('');
  const [open, setOpen] = useState(false);
  const [message, setMessage] = useState('');
  const [categorySlug, setCategorySlug] = useState('');
  const [subcategorySlug, setSubcategorySlug] = useState('');
  

  const [isTopicModalOpen, setIsTopicModalOpen] = useState(false);
  const [topicInputText, setTopicInputText] = useState('');
  const [topicList, setTopicList] = useState([]);
  const [topicSearchTerm, setTopicSearchTerm] = useState('');


  const handleTopicSearch = async () => {
    try {
      const response = await axios.post('https://admin.aracademy360.com/generate_topic', { text: topicInputText });
      setTopicList(response.data);
    } catch (error) {
      console.error('Error generating topics:', error);
      showSnackbar('Failed to generate topics');
    }
  };
  
  const filteredTopics = topicList.filter(topic =>
    topic.topic.toLowerCase().includes(topicSearchTerm.toLowerCase())
  );
  
  const handleTopicClick = (topicText) => {
    setInputText(topicText);
    setIsTopicModalOpen(false);
  };

  const handleClose = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }
    setOpen(false);
  };

  const showSnackbar = (msg) => {
    setMessage(msg);
    setOpen(true);
  };

  const handleAddSubcategory = async () => {
    try {
      await axios.post('https://admin.aracademy360.com/add_subcategory', {
        category_id: newSubcategoryCategory,
        subcategory_name: newSubcategoryName
      });
      showSnackbar('Subcategory added successfully!');

      setIsAddSubcategoryModalOpen(false);
      // Refresh subcategories
      handleCategoryChange({ target: { value: selectedCategory } });
    } catch (error) {
      console.error('Error adding subcategory:', error);
      showSnackbar('Failed to add subcategory');
    }
  };
  
// Ensure the first question's subcategory value is populated when the modal is opened
useEffect(() => {
  if (isAddSubcategoryModalOpen && questions.length > 0) {
    const firstSubcategory = questions[0]?.subcategory || '';
    setNewSubcategoryName(firstSubcategory);
  }
}, [isAddSubcategoryModalOpen, questions]);
  useEffect(() => {
    const fetchCategories = async () => {
      try {
        const response = await axios.get('https://admin.aracademy360.com/get_category');
        setCategories(response.data);
      } catch (error) {
        console.error('Error fetching categories:', error);
      }
    };

    fetchCategories();
  }, []);

  const handleGenerate = async () => {
    setIsLoading(true);
    try {
      const response = await axios.post('https://admin.aracademy360.com/generate', { text: inputText });
      setQuestions(response.data);
    } catch (error) {
      console.error('Error generating questions:', error);
    }
    setIsLoading(false);
  };

  const handleEditClick = (question, index) => {
    setEditingQuestion({ ...question, index });
    setIsModalOpen(true);
  };

  const handleModalInputChange = (field, value) => {
    setEditingQuestion(prev => ({
      ...prev,
      [field]: field === 'options' ? value : value
    }));
  };

  const handleSaveEdit = () => {
    const updatedQuestions = questions.map((q, idx) =>
      idx === editingQuestion.index ? { ...editingQuestion } : q
    );
    setQuestions(updatedQuestions);
    setIsModalOpen(false);
  };

  const handleSubmit = () => {
    setIsSubmitModalOpen(true);
  };

  const handleCategoryChange = async (event) => {
    const categoryId = event.target.value;
    setSelectedCategory(categoryId);
    try {
      const response = await axios.get(`https://admin.aracademy360.com/get_subcategories/${categoryId}`);
      const subcategoryData = response.data.map(item => ({
        id: item.subcategory_id,
        name: item.subcategory_name,
        subcategory_slug: item.subcategory_slug
      }));
      console.log(subcategoryData);
      setSubcategories(subcategoryData);
      const slug = event.target.options[event.target.selectedIndex].getAttribute('slug');
      setCategorySlug(slug);
    } catch (error) {
      console.error('Error fetching subcategories:', error);
    }
  };

  const handleSubcategoryChange = (e) => {
    const selectedSubcategoryId = e.target.value;
    setSelectedSubcategory(selectedSubcategoryId);
    const slug = e.target.options[e.target.selectedIndex].getAttribute('slug');
    setSubcategorySlug(slug);
  };

  const handleSubmitQuestions = async () => {
    try {
      const data = {
        questions,
        category_id: selectedCategory,
        subcategory_id: selectedSubcategory
      };
      await axios.post('https://admin.aracademy360.com/submit_questions', data)
        .then((resp) => {          
          setIsSubmitModalOpen(false);

          if (resp.data.level !== undefined && resp.data.level !== null) {
            
              showSnackbar('Questions submitted successfully!');
              // Construct the URL using the slugs
              const URL = `http://localhost:3000`;
              const path = `/quiz-zone/level/${subcategorySlug}/?catid=${categorySlug}&subcatid=${subcategorySlug}&isSubcategory=1&is_play=0`;
      
              const data = {
                url:URL,
                path:path,
                level:resp.data.level
              };
              
// setTimeout(() => {
                axios.post('http://127.0.0.1:5000/generate_video', data)
                .then((resp) => {
                    if (resp.data.message !== undefined && resp.data.message !== null) {
                    setMessage(resp.data.message);
                    }
                })
                .catch((error) => {
                  console.error('Error generating video:', error);
                  setMessage('Error generating video');
                });

              }
              
// }, 5000);
          })
        .catch(error => {
          console.error('Error submitting questions:', error);
          setMessage('Error submitting questions');
        });
      
    } catch (error) {
      console.error('Error submitting questions:', error);
    }
  };

  return (
    <div className="min-h-screen p-5 bg-gray-100">
      <div className="mx-auto bg-white p-5 rounded-lg shadow-md">
        <h1 className="text-2xl font-bold mb-4">Question Generator</h1>
        <textarea
          className="w-full p-2 mb-4 border rounded"
          value={inputText}
          onChange={(e) => setInputText(e.target.value)}
          placeholder="Enter text to generate questions"
          rows="4"
        ></textarea>
        
        <div style={{display:"flex",flexDirection:"row",justifyContent: "space-between"}}>
        <button
          className="bg-blue-500 text-white px-4 py-2 rounded"
          onClick={handleGenerate}
          disabled={isLoading}
        >
          {isLoading ? 'Generating...' : 'Generate'}
        </button>
          <button
            className="bg-purple-500 text-white px-4 py-2 rounded mr-2"
            onClick={() => setIsTopicModalOpen(true)}
          >
            Search Topics
          </button>
        </div>
        {isTopicModalOpen && (
  <div className="fixed inset-0 bg-gray-600 bg-opacity-50 overflow-y-auto h-full w-full">
    <div className="relative top-20 mx-auto p-5 border w-3/4 max-w-2xl shadow-lg rounded-md bg-white">
      <h3 className="text-lg font-bold mb-4">Search Topics</h3>
      <div className="mb-4">
        <textarea
          className="w-full p-2 border rounded"
          value={topicInputText}
          onChange={(e) => setTopicInputText(e.target.value)}
          placeholder="Enter text to generate topics"
          rows="4"
        ></textarea>
      </div>
      <div className="mb-4">
        <button
          className="bg-blue-500 text-white px-4 py-2 rounded"
          onClick={handleTopicSearch}
        >
          Generate Topics
        </button>
      </div>
      {topicList.length > 0 && (
        <>
          <div className="mb-4">
            <input
              type="text"
              className="w-full p-2 border rounded"
              placeholder="Search topics..."
              value={topicSearchTerm}
              onChange={(e) => setTopicSearchTerm(e.target.value)}
            />
          </div>
          <div className="max-h-60 overflow-y-auto">
            <ul className="list-disc pl-5">
              {filteredTopics.map((topic, index) => (
                <li
                  key={index}
                  className="mb-2 cursor-pointer"
                  onClick={() => handleTopicClick(topic.topic)}
                >
                  {topic.topic} - Priority: {topic.priority}%
                </li>
              ))}
            </ul>
          </div>
        </>
      )}
      <div className="flex justify-end mt-4">
        <button
          className="bg-gray-300 px-4 py-2 rounded"
          onClick={() => setIsTopicModalOpen(false)}
        >
          Close
        </button>
      </div>
    </div>
  </div>
)}
        {questions.length > 0 && (
          <>
          <div className="mt-5">
            <table className="min-w-full bg-white border">
              <thead>
                <tr>
                  <th className="border px-4 py-2">Question Type</th>
                  <th className="border px-4 py-2">Question</th>
                  <th className="border px-4 py-2">Options</th>
                  <th className="border px-4 py-2">Answer</th>
                  <th className="border px-4 py-2">Level</th>
                  <th className="border px-4 py-2">Note</th>
                  <th className="border px-4 py-2">Action</th>
                </tr>
              </thead>
              <tbody>
                {questions.map((question, index) => (
                  <tr key={index}>
                    <td className="border px-4 py-2">{question.question_type}</td>
                    <td className="border px-4 py-2">{question.question}</td>
                    <td className="border px-4 py-2">{question.options.join(', ')}</td>
                    <td className="border px-4 py-2">{question.answer}</td>
                    <td className="border px-4 py-2">{question.level}</td>
                    <td className="border px-4 py-2">{question.note}</td>
                    <td className="border px-4 py-2">
                      <button
                        className="bg-yellow-500 text-white px-2 py-1 rounded"
                        onClick={() => handleEditClick(question,index)}
                      >
                        Edit
                      </button>
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
            
        <div style={{display:"flex",flexDirection:"row",justifyContent: "space-around"}}>
          
        <button
          className="mt-4 bg-blue-500 text-white px-4 py-2 rounded"
          onClick={() => setIsAddSubcategoryModalOpen(true)}
        >
          Add Subcategory
        </button>
            <button
              className="mt-4 bg-green-500 text-white px-4 py-2 rounded"
              onClick={handleSubmit}
            >
              Submit Questions
            </button>
          </div>
          </div>
          </>
        )}
      </div>

      {isAddSubcategoryModalOpen && (
  <div className="fixed inset-0 bg-gray-600 bg-opacity-50 overflow-y-auto h-full w-full">
    <div className="relative top-20 mx-auto p-5 border w-96 shadow-lg rounded-md bg-white">
      <h3 className="text-lg font-bold mb-4">Add Subcategory</h3>
      <div className="mb-4">
        <label className="block mb-2">Category</label>
        <select
          className="w-full p-2 border rounded"
          value={newSubcategoryCategory}
          onChange={(e) => setNewSubcategoryCategory(e.target.value)}
        >
          <option value="">Select Category</option>
          {Object.keys(categories).map((categoryId) => (
            <option key={categoryId} slug={categories[categoryId].category_slug} value={categoryId}>
              {categories[categoryId].name}
            </option>
          ))}
        </select>
      </div>
      <div className="mb-4">
        <label className="block mb-2">Subcategory Name</label>
        <input
          type="text"
          className="w-full p-2 border rounded"
          value={newSubcategoryName}
          onChange={(e) => setNewSubcategoryName(e.target.value)}
        />
      </div>
      <div className="flex justify-end">
        <button
          className="bg-green-500 text-white px-4 py-2 rounded mr-2"
          onClick={handleAddSubcategory}
        >
          Add
        </button>
        <button
          className="bg-gray-300 px-4 py-2 rounded"
          onClick={() => setIsAddSubcategoryModalOpen(false)}
        >
          Cancel
        </button>
      </div>
    </div>
  </div>
)}

      {isModalOpen && (
        <div className="fixed inset-0 bg-gray-600 bg-opacity-50 overflow-y-auto h-full w-full">
          <div className="relative top-20 mx-auto p-5 border w-96 shadow-lg rounded-md bg-white">
            <h3 className="text-lg font-bold mb-4">Edit Question</h3>
            <div className="mb-4">
              <label className="block mb-2">Question Type</label>
              <input
                type="number"
                className="w-full p-2 border rounded"
                value={editingQuestion.question_type}
                onChange={(e) => handleModalInputChange('question_type', e.target.value)}
              />
            </div>
            <div className="mb-4">
              <label className="block mb-2">Question</label>
              <input
                type="text"
                className="w-full p-2 border rounded"
                value={editingQuestion.question}
                onChange={(e) => handleModalInputChange('question', e.target.value)}
              />
            </div>
            {editingQuestion.options.map((option, index) => (
              <div key={index} className="mb-4">
                <label className="block mb-2">Option {index + 1}</label>
                <input
                  type="text"
                  className="w-full p-2 border rounded"
                  value={option}
                  onChange={(e) => {
                    const newOptions = [...editingQuestion.options];
                    newOptions[index] = e.target.value;
                    handleModalInputChange('options', newOptions);
                  }}
                />
              </div>
            ))}
            <div className="mb-4">
              <label className="block mb-2">Answer</label>
              <input
                type="text"
                className="w-full p-2 border rounded"
                value={editingQuestion.answer}
                onChange={(e) => handleModalInputChange('answer', e.target.value)}
              />
            </div>
            <div className="mb-4">
              <label className="block mb-2">Level</label>
              <input
                type="number"
                className="w-full p-2 border rounded"
                value={editingQuestion.level}
                onChange={(e) => handleModalInputChange('level', e.target.value)}
              />
            </div>
            <div className="mb-4">
              <label className="block mb-2">Note</label>
              <input
                type="text"
                className="w-full p-2 border rounded"
                value={editingQuestion.note}
                onChange={(e) => handleModalInputChange('note', e.target.value)}
              />
            </div>
            <div className="flex justify-end">
              <button
                className="bg-blue-500 text-white px-4 py-2 rounded mr-2"
                onClick={handleSaveEdit}
              >
                Save
              </button>
              <button
                className="bg-gray-300 px-4 py-2 rounded"
                onClick={() => setIsModalOpen(false)}
              >
                Cancel
              </button>
            </div>
          </div>
        </div>
      )}

      {isSubmitModalOpen && (
        <div className="fixed inset-0 bg-gray-600 bg-opacity-50 overflow-y-auto h-full w-full">
          <div className="relative top-20 mx-auto p-5 border w-96 shadow-lg rounded-md bg-white">
            <h3 className="text-lg font-bold mb-4">Submit Questions</h3>
            <div className="mb-4">
              <label className="block mb-2">Category</label>
              <select
                className="w-full p-2 border rounded"
                value={selectedCategory}
                onChange={handleCategoryChange}
              >
                <option value="">Select Category</option>
                {Object.keys(categories).map((categoryId) => (
                  <option key={categoryId}  slug={categories[categoryId].category_slug} value={categoryId}>
                    {categories[categoryId].name}
                  </option>
                ))}
              </select>
            </div>
            <div className="mb-4">
              <label className="block mb-2">Subcategory</label>
              <select
                className="w-full p-2 border rounded"
                value={selectedSubcategory}
                onChange={handleSubcategoryChange}
              >
                <option value="">Select Subcategory</option>
                {subcategories.map((subcategory) => (
                  <option key={subcategory.id}  slug={subcategory.subcategory_slug} value={subcategory.id}>
                    {subcategory.name}
                  </option>
                ))}
              </select>
            </div>
            <div className="flex justify-end">
              <button
                className="bg-green-500 text-white px-4 py-2 rounded mr-2"
                onClick={handleSubmitQuestions}
              >
                Submit
              </button>
              <button
                className="bg-gray-300 px-4 py-2 rounded"
                onClick={() => setIsSubmitModalOpen(false)}
              >
                Cancel
              </button>
            </div>
          </div>
        </div>
      )}
      <Snackbar
        open={open}
        autoHideDuration={6000}
        onClose={handleClose}
        message={message}
        anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
      />
    </div>
    
  );
};

export default App;
